import React, {ChangeEvent, Dispatch, ReactElement, SetStateAction, useEffect, useState} from "react";
import {
    Avatar,
    Box, Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    Grid2,
    Stack, TextField,
    Toolbar,
    Typography
} from "@mui/material";
import InfiniteScroll from 'react-infinite-scroll-component';
import {REST} from "../REST";
import {MenuBlock} from "../component/MenuBlock";
import {MessageItem} from "../component/MessageItem";
import {MessageType} from "../type/MessageType";
import {useNavigate, useParams} from "react-router-dom";
import {Normalizer} from "../utils/Normalizer";
import {HTMLInput} from "../component/HTMLInput";
import {UserType} from "../type/UserType";

export default function ThemePage(props: any): ReactElement {
    const [me, setMe] = props.me as [UserType, Dispatch<SetStateAction<UserType>>];
    const {tid} = useParams<string>();
    const navigate = useNavigate();

    const pageSize: number = 10;
    const [items, setItems] = useState<MessageType[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);

    const [message, setMessage] = useState<string>('');
    const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (currentPage === 0) {
            REST.getForumMessages(BigInt(tid!), currentPage, pageSize).then(nn => {
                if (nn.length < pageSize) {
                    setHasMore(false);
                }
                setItems(nn);
            });
        }
    }, [tid, currentPage]);

    const fetchMore = () => {
        REST.getForumMessages(BigInt(tid!), currentPage, pageSize).then(nn => {
            setCurrentPage(prevPage => prevPage + 1);
            setItems(prevItems => [...prevItems, ...nn]);
        });
    };

    const handleOnChangeMessage = (event: ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
        if (event.target.value.length > 0) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        }
    }

    const sendMessage = () => {
        const newMessage: MessageType = {
            id: BigInt(0),
            tid: BigInt(tid!),
            author: me,
            content: message,
            publishDate: '',
            themeStatus: 0
        }
        REST.sendMessage(newMessage).then(m => {
            setMessage('');
            setCurrentPage(0);
            window.location.reload();//TODO: нужно более элегантное решение
        });
    }

    const closeTheme = () => {
        REST.closeTheme(BigInt(tid!)).then(() => {
            navigate("/forum/");
        });
    }

    return (<>
        <Grid2 sx={{minHeight: '100vh'}}>

            <MenuBlock me={[me, setMe]}/>
            <Toolbar sx={{mb: 1}}/>

            <Container>
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMore}
                    hasMore={hasMore}
                    loader={<Box sx={{width: '100%', textAlign: 'center', overflow: 'hidden', mt: 1}}><CircularProgress
                        color="inherit"/></Box>}
                    endMessage={items.length > 0 ? '' :
                        <Box sx={{width: '100%', textAlign: 'center', overflow: 'hidden', mt: 1}}>
                            <Typography>Нет сообщений</Typography></Box>}
                >
                    {items.map((item, index) => (
                        <MessageItem key={item.id} item={item} index={index}/>
                    ))}
                </InfiniteScroll>

                {me.id !== undefined && (items[0]?.themeStatus ?? 1) === 1
                    ? <Card sx={{mt: 1, mb: 1}} variant="outlined">
                        <CardContent sx={{display: 'flex'}}>
                            <Grid2 container spacing={1} columns={6} sx={{width: "100%"}}>

                                <Grid2 container size={5} spacing={1} columns={1}>
                                    <TextField sx={{mt: 1}}
                                               id={"message"}
                                               label={"Новое сообщение"}
                                               name={"message"}
                                               fullWidth
                                               required
                                               multiline
                                               rows={15}
                                               slotProps={{
                                                   input: {
                                                       inputComponent: HTMLInput as any
                                                   },
                                                   inputLabel: {shrink: true},
                                               }}
                                               onChange={handleOnChangeMessage}
                                               value={message}
                                    />
                                </Grid2>

                                <Grid2 container size={1} spacing={1} columns={1}>
                                    <Stack sx={{
                                        width: '100%',
                                        justifyContent: "end",
                                        color: 'text.secondary',
                                        alignItems: "center"
                                    }}>
                                        <Avatar src={REST.BASE + '/api/user/' + me.id + '/avatar'} variant="square"/>
                                        <Typography variant={'body2'}
                                                    align={'center'}>{Normalizer.shortFio(me.fio)}</Typography>

                                        {Normalizer.hasRole("admin") || Normalizer.hasRole("chairman") || Normalizer.hasRole("board_member")
                                            ? <Button color={'error'} type="submit"
                                                      variant={"contained"} sx={{mt: 1}}
                                                      onClick={() => closeTheme()}>Закрыть тему</Button>
                                            : ""
                                        }
                                        <Button color={'primary'} type="submit" disabled={saveDisabled}
                                                variant={"contained"} sx={{mt: 1}}
                                                onClick={() => sendMessage()}>Отправить</Button>
                                    </Stack>
                                </Grid2>
                            </Grid2>
                        </CardContent>
                    </Card>
                    : (items[0]?.themeStatus ?? 1) === 2
                        ? <Card sx={{mt: 0}} variant="outlined">
                            <CardContent sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant={'subtitle2'} align={'center'} color={"error"}>Тема
                                    закрыта</Typography>
                            </CardContent>
                        </Card>
                        : ""
                }
            </Container>

        </Grid2>
    </>)
}