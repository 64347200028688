import React, {ReactElement} from "react";
import {REST} from "../REST";
import {Avatar, Card, CardContent, Grid2, Stack, Typography} from "@mui/material";
import {Normalizer} from "../utils/Normalizer";
import {MessageType} from "../type/MessageType";

export function MessageItem(props: any): ReactElement {
    const item: MessageType = props.item as MessageType;
    const index: number = props.index;

    return (
        <Card sx={{mt: 1, mb: 1}} variant="outlined">
            <CardContent sx={{display: 'flex'}}>
                <Grid2 container spacing={1} columns={4}>
                    <Grid2 container size={1} spacing={1} columns={1}>
                        <Typography variant={'body2'}
                                    align={'center'}>{Normalizer.shortFio(item.author.fio)}</Typography>

                        <Stack sx={{
                            width: '100%',
                            justifyContent: "center",
                            color: 'text.secondary',
                            alignItems: "center"
                        }}>
                            <Avatar src={REST.BASE + '/api/user/' + item.author.id + '/avatar'} variant="square"/>
                            <Typography variant={'body2'}
                                        align={'center'}>{"Группа: " + (item.author.roles?.map(r => r.name).join(', ') ?? '-')}</Typography>
                        </Stack>
                    </Grid2>
                    <Grid2 container size={3} spacing={1} columns={1}>
                        <Stack direction={'row'} spacing={1} sx={{width: '100%', justifyContent: "space-between",}}>
                            <Typography variant={'caption'}>{item.publishDate}</Typography>
                            <Typography variant={'caption'}>Сообщение №{index + 1}</Typography>
                        </Stack>

                        <Typography variant="body2" sx={{color: 'text.secondary'}}
                                    dangerouslySetInnerHTML={{__html: item.content}}/>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    );
}