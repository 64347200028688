import React, {ReactElement, useState} from "react";
import {
    Stack,
    Card,
    Avatar,
    CardContent,
    Typography,
    CardActions,
    Chip, Divider, CardActionArea
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {NewsType} from "../type/NewsType";
import {REST} from "../REST";
import {Normalizer} from "../utils/Normalizer";

export function NewsItem(props: any): ReactElement {
    const itemPart: NewsType = props.news as NewsType;
    const [liked, setLiked] = useState<boolean>(itemPart.liked);
    const [countLike, setCountLike] = useState<number>(itemPart.countLike);

    const handleLike = () => {
        REST.like(itemPart.id).then((n) => {
            setLiked(n.liked);
            setCountLike(n.countLike);
        }).catch(e => {

        });
    };

    return (
        <Card sx={{mt: 1, mb: 1}} variant="outlined">
            <CardContent>
                <Typography variant="h6" align={'center'} gutterBottom>{itemPart.title}</Typography>
                <Typography variant="body2" sx={{color: 'text.secondary'}} align={'center'}
                            dangerouslySetInnerHTML={{__html: itemPart.content}}/>
            </CardContent>
            <Divider/>
            <CardActions disableSpacing>
                <Chip icon={<FavoriteIcon color={liked ? 'error' : undefined}/>} label={countLike} sx={{mr: 1}}
                      onClick={handleLike}/>

                <Stack direction="row" sx={{ml: 'auto'}} spacing={1}>
                    <Avatar src={REST.BASE + '/api/user/' + itemPart.author.id + '/avatar'}/>
                    <Stack>
                        <Typography variant={'body2'}>{Normalizer.shortFio(itemPart.author.fio)}</Typography>
                        <Typography variant={'caption'}>{itemPart.publishDate}</Typography>
                    </Stack>
                </Stack>
            </CardActions>
        </Card>
    );
}

export function SimpleNewsItem(props: any): ReactElement {
    const item: NewsType = props.news as NewsType;
    const onClick = props.onClick;

    return (
        <Card variant="outlined" sx={{flex: "1 0 33%"}}>
            <CardActionArea onClick={onClick} sx={{height: "100%", alignItems: "flex-start", display: "flex"}}>
                <CardContent>
                    <Typography variant={'caption'}>{item.publishDate}</Typography>

                    <Typography variant="subtitle2" align={'center'} gutterBottom>{item.title}</Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}} align={'center'}
                                dangerouslySetInnerHTML={{__html: item.content.substring(0, Math.min(item.content.length, 750))}}/>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}