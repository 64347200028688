import React, {ReactElement, useEffect, useState} from "react";
import {Normalizer} from "../../utils/Normalizer";
import {Navigate} from "react-router-dom";
import {Card, CardActionArea, CardContent, Container, Grid2, Toolbar} from "@mui/material";
import {AdminMenuBlock} from "../../component/admin/AdminMenuBlock";
import {BannerType} from "../../type/BannerType";
import {REST} from "../../REST";
import {BannerItem} from "../../component/BannerItem";
import BannerModal from "../../modal/BannerModal";
import AddIcon from '@mui/icons-material/Add';

export default function AdminBannerPage(props: any): ReactElement {
    const [me, setMe] = props.me;
    const [items, setItems] = useState<BannerType[]>([]);
    const [current, setCurrent] = useState<BannerType | null>(null);
    const empty: BannerType = {id: BigInt(0), title: null, text: ''};

    useEffect(() => {
        REST.getBanners().then(bb => {
            setItems(bb);
        }).catch(e => {

        });
    }, [current])

    if(me.id === undefined || !(Normalizer.hasRole("admin") || Normalizer.hasRole("chairman"))) {
        return (<Navigate to={"/"}/>);
    }

    return (
        <Grid2 sx={{minHeight: '100vh'}}>

            <AdminMenuBlock me={[me, setMe]}/>
            <Toolbar sx={{mb: 1}}/>

            <Container>
                <Grid2 container spacing={1} columns={{ xs: 1, sm: 4 }} sx={{mt: 2}}>

                        <Card sx={{mb: 1, maxWidth: '300px', minWidth: '150px', flex: "1 0 25%"}} variant="outlined">
                            <CardActionArea onClick={() => setCurrent(empty)} sx={{height: "100%"}}>
                                <CardContent sx={{textAlign: 'center'}}>
                                    <AddIcon/>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                    {items.map((b, index) => <BannerItem key={b.id} item={b} onClick={() => setCurrent(b)}/>)}
                </Grid2>
            </Container>

            <BannerModal banner={[current, setCurrent]} me={[me, setMe]}/>
        </Grid2>
    );
}