import React, {ReactElement} from "react";
import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography
} from "@mui/material";
import {BannerType} from "../type/BannerType";
import {REST} from "../REST";

export function BannerItem(props: any): ReactElement {
    const item: BannerType = props.item as BannerType;
    const onClick = props.onClick;

    return (
        <Card sx={{mb: 1, maxWidth: '300px', minWidth: '150px', flex: "1 0 25%"}} variant="outlined">
            <CardActionArea onClick={onClick}>
                <CardMedia image={REST.BASE + "/api/banner/" + item.id + "/img"}/>
                <CardContent>
                    {item.title !== null
                        ? <Typography variant="subtitle2" align={'center'} gutterBottom>{item.title}</Typography>
                        : ""
                    }
                    <Typography variant="body2" sx={{color: 'text.secondary', textWrap: 'nowrap'}}
                                align={'center'} dangerouslySetInnerHTML={{__html: item.text}}/>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}